import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

export const DayPassTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content

  return (
    <div>
      <section className="py-10 pt-md-11 bg-dark">
        <div className="container-lg">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-8 text-center text-white">
              <h6 className="text-uppercase text-warning mb-5">
                Welcome to Reno Collective
              </h6>

              <h2 className="display-3 mb-4">Reserve a Day Pass</h2>

              <p className="font-size-lg">Come try it out with us!</p>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-10 pt-md-12">
        <div className="container-lg">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-5">
              <img
                className="img-fluid mb-4 mb-md-0"
                src="/img/collective/work_studio.jpg"
                alt="Reno Collective Coworking"
              />
            </div>
            <div className="col-md-7 col-lg-6">
              <h2 className="display-4 mb-4">Get more things done</h2>
              <p className="text-muted">
                Just visiting for a day? Not sure if you are ready to become a
                member? We understand, grab a day pass and access Reno
                Collective for a day! This gives you almost all the perks of a
                member except the conference rooms.
              </p>
              <p className="text-muted">
                A day pass gets you access to Reno Collective from 9:00 am to
                5:00pm. A nice full day.
              </p>
              <p className="text-muted">
                Below we have som FAQ's. Read through them and then schedule
                your day pass!
              </p>
              <div className="row">
                <div className="col-6">
                  <ul className="list-checked list-checked-warning mb-0">
                    <li className="mb-3">Quiet workspace</li>
                    <li className="mb-3">Phone booths</li>
                    <li>Complementary coffee & tea</li>
                  </ul>
                </div>
                <div className="col-6">
                  <ul className="list-checked list-checked-warning mb-0">
                    <li className="mb-3">Awesome location</li>
                    <li>Inspiring, work-minded community</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-5 pt-md-12">
        <div className="container-lg">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-5 order-md-1">
              <img
                className="img-fluid mb-8 mb-md-0"
                src="/img/home/phonerooms.jpg"
                alt="Phone Rooms"
              />
            </div>
            <div className="col-md-7 col-lg-6 order-md-0">
              <h2 className="display-4 mb-4">FAQs</h2>
              <p className="text-muted">
                <strong>Can I bring a guest?</strong>
                <br />
                Since day passes are billed on a per person basis, any guest you
                want to bring will also need to purchase a day pass. If you are
                planning on having a meeting with your guest, please book the
                meeting room space.
              </p>
              <p className="text-muted">
                <strong>Do I get access to the conference room?</strong>
                <br />
                Our conference rooms are only available to members at this time.
              </p>
              <p className="text-muted">
                <strong>
                  What if I need to make phone calls or join a zoom?
                </strong>
                <br />
                We have phone booths and phone rooms throughout the space that
                you can use to take these meetings.
              </p>
              <p className="text-muted">
                <strong>What if I need to be on the phone all day?</strong>
                <br />
                Unfortunately, if your work is predominately phone based, we are
                not going to be the best workspace for you as all of our members
                need to be able to use phone rooms throughout the day.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-10 pt-md-12 pb-11">
        <div class="container-lg">
          <div class="row justify-content-center">
            <div class="col-md-10 col-lg-8 text-center">
              <h2 class="display-1 mb-4">Sorry, we are not offering day passes at this time.</h2>
              
              <p class="p-1">
                <small>
                  <Link
                    to="/join"
                    class="text-primary-light"
                    title="memberships"
                  >
                    Interested in becoming a member?
                  </Link>
                </small>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

DayPassTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const DayPassPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <DayPassTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  )
}

DayPassPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default DayPassPage

export const dayPassPageQuery = graphql`
  query DayPassPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
